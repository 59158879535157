import React, { useState, useEffect } from "react";
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer';
import { Form, Toast, Button, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faVoicemail, faExclamationTriangle, faPhone } from "@fortawesome/free-solid-svg-icons";
import "./contact.css"
import { faEnvelope, faTimesCircle, faCheckCircle } from "@fortawesome/free-regular-svg-icons";

import history from '../../helper/history'
import useApiRequest from '../../useApiRequest/';
import { FETCHING, SUCCESS, ERROR } from '../../useApiRequest/actionTypes';
import { getToken } from '../../auth';
import { host, apiurl } from '../../helper';

const colorSystem = {
    error: '#B00020',
    sucess: '#4CAF50',
    warnning: '#ee6002'
}

const Contact = () => {
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState({
        icon: "",
        title: "",
        message: "",
        headerColor: ""
    });
    const [name, setName] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [message, setMessage] = useState(undefined);
    const [phone, setPhone] = useState(undefined);
    const [token] = useState(getToken());
    const [disableButton, setDisableButton] = useState(false);

    const [{ status, response }, makeRequest] = useApiRequest(
        `${host}${apiurl}/mail/send`,
        {
            token: `${token}`,
            options: {
                'method': 'POST',
                'body': JSON.stringify({ name, email, message, phone })
            }
        }
    );

    useEffect(() => {
        switch (status) {
            case FETCHING:
                setDisableButton(true)
                break;
            case ERROR:
                setToastMessage({
                    icon: faTimesCircle,
                    title: "Erro!!!",
                    message: "Erro ao enviar sua mensagem, tente mais tarde!",
                    headerColor: colorSystem.error
                })
                setShowToast(true);
                setDisableButton(false)
                break;
            case SUCCESS:
                setToastMessage({
                    icon: faCheckCircle,
                    title: "Sucesso",
                    message: "Sua mensagem foi enviada com sucesso",
                    headerColor: colorSystem.sucess
                })
                setShowToast(true);
                setDisableButton(false)
                break;

            default:
                break;
        }

    }, [response, status])

    const handleMailSend = () => {
        if (name && email && message) {
            makeRequest();
        } else {
            setToastMessage({
                icon: faExclamationTriangle,
                title: "Atenção!!!",
                message: "Preencha todos os campos antes de enviar!",
                headerColor: colorSystem.warnning
            })
            setShowToast(true)
        }
    }

    const renderToast = (toastMessage) => (
        <Toast
            style={{ position: 'absolute', top: 16, right: 16 }} onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
            <Toast.Header style={{ backgroundColor: toastMessage.headerColor }}>
                <FontAwesomeIcon icon={toastMessage.icon} style={{ marginRight: 16 }} color="#fff" />
                <strong className="mr-auto toast-title">{toastMessage.title}</strong>
            </Toast.Header>
            <Toast.Body>{toastMessage.message}</Toast.Body>
        </Toast>
    )

    return (
        <div className="app-pages--acoount app-pages--register">
            <div className="section-panel section-panel--start section-panel--flex">
                <div className="component-account--container">
                    <Header />
                    <h2 className="text--capitalize">Contacto</h2>
                    <p>Entre em contato e comece a usar o DaSinal na sua organização</p>
                    <Form className="signup-form">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="organization-addon"><FontAwesomeIcon icon={faHome} size="lg" /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder="Nome da Organização"
                                aria-label="organization"
                                aria-describedby="organization-addon"
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="email-addon"><FontAwesomeIcon icon={faVoicemail} size="lg" /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                type="email"
                                placeholder="E-mail"
                                aria-label="E-mail"
                                aria-describedby="email-addon"
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="phone-addon"><FontAwesomeIcon icon={faPhone} size="lg" /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                                placeholder="Telefone"
                                aria-label="Telefone"
                                aria-describedby="phone-addon"
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="message-addon"><FontAwesomeIcon icon={faEnvelope} size="lg" /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                as="textarea"
                                aria-label="With textarea"
                                placeholder="Mensagem"
                            />
                        </InputGroup>

                        <div >
                            <Button disabled={disableButton} className="button-primary text--capitalize" variant="success" onClick={handleMailSend} block> Enviar</Button>
                            <Button disabled={disableButton} className="text--capitalize" variant="secondary" onClick={() => history.push('/')} block> Voltar </Button>
                        </div>
                    </Form>
                    <Footer />
                </div>
            </div>
            {showToast && renderToast(toastMessage)}
        </div>
    );
}

export default Contact;