import React from "react";
import './about.css';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <section className="section-account">
            <h2 className="text--capitalize">O QUE FAZEMOS</h2>
            <p>Fornecemos serviços de notificação e acompanhamento dos processos administrativos e objetos, por vários canais utilizando recursos inteligente e inovadora.
                    <br />Façamos com que os seus serviços sejam rastreáveis e acompanhados pelos seus UTENTES em tempo real.</p>
            <Link className="btn btn-success btn-lg text--capitalize" to="/contact">QUERO PARA MINHA INSTITUIÇÃO</Link>
            <p className="text-size--small">DaSinal / Notificação e Acompanhamento</p>
        </section>
    );
}

export default About;