import React from "react";
import "./home.css";

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Track from "../../components/track/track";
import NavbarComponent from "../../components/navbar/navbar";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBoxOpen,
    faBusinessTime,
    faLightbulb,
    faSms,
    faAt,
    faAsterisk,
    faBell,
} from "@fortawesome/free-solid-svg-icons";
import { faFile, } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp, faViber, faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import About from "../../components/about/about";

const Home = (props) => {
    return (
        <div className="app-pages--home">
            <NavbarComponent />
            <div className="section-panel section-panel--track">
                <Header />
                <Track
                    textareaRows="5"
                    textareaPlaceholder="Introduza o código de acompanhamento,  um por linha!"
                />
            </div>

            <div className="section-panel section-panel--wct">
                <section className="section-whatcantrack">
                    <h2 className="text--capitalize">VOCÊ CIDADÃO</h2>

                    <p>Acompanhe os processos administrativos, documentos, pacotes / objetos e muitos mais de forma simples, cómoda e segura.
                    <br />Poupe nas deslocações ás instituições para saber do estado dos seus processos. Obtenha estas informações sem se deslocar, na sua comodidade por vários canais.</p>
                </section>
            </div>

            <div className="section-panel section-panel--wct">
                <section className="section-whatcantrack">
                    <h2 className="text--capitalize">CANAIS</h2>

                    <Row className="justify-content-md-center" noGutters={false} xs={1} sm={2} md={3}>

                        <Col>
                            <div className="icon-container sms">
                                <FontAwesomeIcon icon={faSms} size="lg" />
                                <p>sms</p>
                            </div>
                        </Col>

                        <Col>
                            <div className="icon-container email">
                                <FontAwesomeIcon icon={faAt} size="lg" />
                                <p>email</p>
                            </div>
                        </Col>

                        <Col>
                            <div className="icon-container whatsapp">
                                <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                                <p>whatsapp</p>
                            </div>
                        </Col>

                        <Col>
                            <div className="icon-container viber">
                                <FontAwesomeIcon icon={faViber} size="lg" />
                                <p>viber</p>
                            </div>
                        </Col>

                        <Col>
                            <div className="icon-container mensseger">
                                <FontAwesomeIcon icon={faFacebookMessenger} size="lg" />
                                <p>messenger</p>
                            </div>
                        </Col>

                        <Col>
                            <div className="icon-container ussd">
                                <FontAwesomeIcon icon={faAsterisk} size="lg" />
                                <p>ussd</p>
                            </div>
                        </Col>

                        <Col>
                            <div className="icon-container push-notification">
                                <FontAwesomeIcon icon={faBell} size="lg" />
                                <p>push notification</p>
                            </div>
                        </Col>

                        <Col>
                            <div className="icon-container da-sinal">
                                <FontAwesomeIcon icon={faLightbulb} size="lg" />
                                <p>web / mobile</p>
                            </div>
                        </Col>
                    </Row>

                </section>
            </div>

            <div className="section-panel section-panel--wct">
                <section className="section-whatcantrack">
                    <h2 className="text--capitalize">Efetuamos Acompanhamento</h2>

                    <Row className="justify-content-md-center" noGutters={false} xs={1} sm={2} md={3}>
                        <Col>
                            <div className="icon-container">
                                <FontAwesomeIcon icon={faFile} size="lg" />
                                <p>Processos</p>
                            </div>
                        </Col>

                        <Col>
                            <div className="icon-container">
                                <FontAwesomeIcon icon={faBusinessTime} size="lg" />
                                <p>Documentos</p>
                            </div>
                        </Col>

                        <Col>
                            <div className="icon-container">
                                <FontAwesomeIcon icon={faBoxOpen} size="lg" />
                                <p>Objectos</p>
                            </div>
                        </Col>

                        <Col>
                            <div className="icon-container">
                                <FontAwesomeIcon icon={faLightbulb} size="lg" />
                                <p>Qualquer Coisa</p>
                            </div>
                        </Col>
                    </Row>
                </section>
            </div>

            <div className="section-panel">
                <About />
            </div>
            <Footer />
        </div>
    );
};

export default Home;
