import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    getYear(){
        return new Date().getFullYear();
    }

    render() {
        return (
            <React.Fragment>
                <footer className="app-footer"> 
                    © <a href="https://www.inforexper.com/" target="_blank" rel="noopener noreferrer">InforExper</a> {this.getYear()}. Todos os Direitos Reservados.
                    <br/>
                    <div>
                        <p>Segue-nos  nas redes sóciais</p>
                        <a href="https://www.facebook.com/dasinal.cv/" target="_blank" rel="noopener noreferrer" title="Facebook" className="social-links social-links--facebook">
                            <FontAwesomeIcon icon={faFacebook}/>
                        </a>

                        <a href="https://www.instagram.com/dasinal.cv/" target="_blank" rel="noopener noreferrer" title="Instagram" className="social-links social-links--instagram">
                            <FontAwesomeIcon icon={faInstagram}/>
                        </a>
                    </div>
                </footer>                
            </React.Fragment>
            
        );
    }
}

export default Footer;