import React, { useState } from "react";
import history from "../../helper/history";
import './track.css';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTruckLoading} from "@fortawesome/free-solid-svg-icons";
import {faPlayCircle} from '@fortawesome/free-regular-svg-icons';
import CodeMirror from 'react-codemirror';
import 'codemirror/addon/display/placeholder';

const Track = (props) => {
    const [trackNumbers, setTrackNumbers] = useState([]);
    const [codemirrorValue, setCodemirrorValue] = useState('');
    const [inputValue, setInputValue] = useState('');

    const run = () => {
        if(codemirrorValue !== '' && trackNumbers.length > 0){            
            history.push('/t/'+trackNumbers);
        }
    }

    const onChangeTextArea = (newValue) => {    
        setCodemirrorValue(newValue.toUpperCase());
        
        let textarea = document.getElementsByName('codemirrorEditor');
        let arrValues = textarea[0].innerHTML.split('\n');
        
        arrValues = arrValues.filter((item)=>{return (item !== '' && typeof item !== 'undefined')});
        
        setTrackNumbers(arrValues);
    }

    const addNumbers = () => {
        props.addNumber(inputValue.toUpperCase());
        setInputValue('');
    }

    const inputRender = (isInput) => {
        let options = {
            lineNumbers: true,
            placeholder: props.textareaPlaceholder
        };

        if(isInput){
            return (
                <React.Fragment>
                    <Form.Control className="track-textarea--lg" as='input' placeholder={props.textareaPlaceholder} value={inputValue} onChange={(event) => setInputValue(event.target.value)}/>
                    <div className="track-button--input">
                        <Button className="track-button--square" variant="success" onClick={() => addNumbers()}>
                            <FontAwesomeIcon icon={faPlayCircle} style={{fontSize:'44px'}}/>
                        </Button>
                    </div>
                </React.Fragment>
            );
        }

        return(
            <React.Fragment>
                <CodeMirror name="codemirrorEditor" value={codemirrorValue} onChange={ (event) => onChangeTextArea(event)} options={options} autoFocus={true}/>
                <div className="track-buttons">
                    <Button name="organizacao" title="Organizações Aderentes" className="track-button--square" variant="outline-secondary" disabled style={{cursor:'not-allowed'}}>
                        <FontAwesomeIcon icon={faTruckLoading}/>
                        <p>Source</p>
                    </Button>
                    <Button name="acompanhameto" className="track-button--square" variant="success" onClick={run} title="Executar Acompanhamento">
                        <FontAwesomeIcon icon={faPlayCircle} style={{fontSize:'44px'}}/>
                    </Button>
                </div>
            </React.Fragment>
        );
    }
    
    return (
        <div className="component-track">
            <Form className={props.isInput?'track-form--input track-form':"track-form"}>
                { inputRender(props.isInput)}
            </Form>
        </div>
    );
}

export default Track;