import React from "react";
import logo from '../../logo-160x128.png';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="component-header">
                <img src={logo} className="app-logo" alt="DaSinal" title="Dasinal / Notificação e Acompanhamento"/>
                <h1>
                    <p>
                        DaSinal / <code> Notificação e Acompanhamento </code>
                    </p>
                </h1>
            </div>
        );
    }
}

export default Header;