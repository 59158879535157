import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from 'react-bootstrap/NavDropdown';
import './navbar.css';
import logo from '../../logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListGroup from "react-bootstrap/ListGroup";
import Media from "react-bootstrap/Media";
import CreateAccount from "../account/createAccount";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import history from "../../helper/history";
import { Link } from "react-router-dom";

import GetClientToken from '../../auth';

const NavbarComponent = (props) => {
    const [sideNavShow] = useState(false);

    const goToLoginPage = () => {
        history.push('login');
    }

    return (
        <div className="component-navbar">
            <GetClientToken />
            <Navbar className="justify-content-between py-0" bg="dark" variant="dark">
                <Navbar.Brand>
                    <Link to="/">
                        <img src={logo} className="d-inline-block" alt="Dasinal" title="DaSinal / Notificação e Acompanhamento" />
                    </Link>
                </Navbar.Brand>
                <NavDropdown title="Idioma" id="basic-nav-dropdown" className="navbar-link--language">
                    <NavDropdown.Item >PORTUGUÊS</NavDropdown.Item>
                </NavDropdown>
            </Navbar>

            <div className="navbar-side" style={{ display: sideNavShow ? 'block' : 'none' }}>
                <ul className="navbar-nav animate side-nav">
                    <ListGroup variant="flush">
                        <ListGroup.Item className="listgroup-item--header">
                        </ListGroup.Item>
                        <ListGroup.Item className="listgroup-item--login" onClick={() => goToLoginPage()}>
                            <Media>
                                <Media.Body>
                                    <h5 className="listgroup-item--p">Sign In</h5>
                                </Media.Body>
                                <FontAwesomeIcon className="listgroup-item--media" icon={faLock} />
                            </Media>
                        </ListGroup.Item>
                        <CreateAccount />
                    </ListGroup>
                </ul>
            </div>

        </div>
    );
}

export default NavbarComponent;