import React, { useEffect } from "react";
import {token} from '../helper';

const setToken = (token) => {
    localStorage.setItem('token', token)
}

export const getToken = () => {
    return localStorage.getItem('token')
}

const setTokenExpire = (time) => {
    localStorage.setItem('expires', time)
}

export const getTokenExpire = () => {
    return localStorage.getItem('expires')
}


const isTokenExpired = () => {
    let token = getToken();
    let expires = getTokenExpire();

    if(token != null && typeof token !== 'undefined' && expires != null && typeof expires != undefined){
        let timestamp = Math.floor(new Date().getTime()/1000);
        return  timestamp > expires;
    }else{
        return true;
    }   
}

export const GetClientToken = () =>{
    
    useEffect(()=>{
        if(isTokenExpired()){
            setToken(token)
        }
    }, []);


    return(
        <React.Fragment></React.Fragment>
    );

}


export default GetClientToken;

  