import { useReducer, useCallback } from 'react';
import reducer, { initialState } from './reducer';
import { fetching, success, error } from './actionCreators';

const useApiRequest = (endpoint, { token = "", options = {} } = {}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const makeRequest = useCallback(async () => {
        dispatch(fetching());
        try {
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'lang': 'pt'
            }

            if (token != null) {
                headers['Authorization'] = token;
            }
            const response = await fetch(endpoint, {
                headers,
                ...options
            }).then((response) => { return response.json() });

            dispatch(success(response));
        } catch (e) {
            dispatch(error(e));
        }
    }, [endpoint, token, options]);

    return [state, makeRequest];
};

export default useApiRequest;