import React from "react";
import {Link} from "react-router-dom";
import './createAccount.css';

const CreateAccount = ()=>{
    return (
        <section className="section-account">
            <h2 className="text--capitalize">Create An Account</h2>
            <p>Status update notifications to know the parcels info in time.</p> 
            <Link className="btn btn-success btn-lg text--capitalize" to="/signup">Get started</Link>
            <p className="text-size--small">Tracking info synchronization among different devices at anytime and anywhere.</p>
        </section>
    );
}

export default CreateAccount;