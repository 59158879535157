import React, { useState, useEffect } from "react";
import './trackDetail.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEllipsisV, faClone, faSyncAlt, faExclamation, faLink, faHome } from "@fortawesome/free-solid-svg-icons";
import { faClock, faStar } from "@fortawesome/free-regular-svg-icons";

import { ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';

import Moment from 'react-moment';
import 'moment/locale/pt';

const format = 'DD MMMM, YYYY / h:mm';

const ICON_FEEDBACK = [
    { status: -1, message: "Não executado" },
    { status: 0, message: "Por executar" },
    { status: 1, message: "Executado" }
]

const TrackDetailComponent = (props) => {
    const [executionPercent, setExecutionPercent] = useState(0);
    const [phaseDefinitionExecution, setPhaseDefinitionExecution] = useState([]);
    const [selectedTrack, setSelectedTrack] = useState({
            code:"",
            description:"",
            url:"",
            status:"",
            created_at:"",
            due_at:"",
            phases: [
                {
                    code:"",
                    description:"",
                    notes:"",
                    created_at:"",
                    due_at:"",
                    phase_definition:"",
                    phase_definition_id:"",
                    is_public:""
                }
            ],
            process_definition: {
                code:"",
                name:"",
                description:"",
                phase_definitions: [
                    { 
                        id:"", 
                        code:"", 
                        name:"",
                        ordain:""
                    }
                ]
            },
            claimants: [
                { 
                    name:"", 
                    address:"", 
                    phone:"", 
                    email:"", 
                    notification_type:""
                }
            ],
            meta_data: {
                execution_percent:"",
                phase_definition_execution:"",
                app_info: { 
                    name:"", 
                    photo:"", 
                    organization:""
                }
            }
        });

    const isFinished = ()=>{
        let status = selectedTrack.status.toLowerCase();
        switch(status){
            case 'finish':
            case 'concluído':
                return true;
            
            default:
                return false
        }
    }

    const showFinishIcon = ()=>{
        if(isFinished()){
            return (<FontAwesomeIcon className="listgroup-item--media item-span--endsuccessfully" icon={faCheck} />)
        }

        return '';
    }
    const trackPhasesDetails = () => {
        if (selectedTrack.phases !== null) {
            if (selectedTrack.phases.length > 0) {
                return selectedTrack.phases.map((item, index) => {
                    return (
                        <li className={(index === 0 && !isFinished()) ? "active" : ''} key={index + 1200}>
                            {showFinishIcon()}
                            <span>{item.description}</span>
                            <span className="float-right"><Moment format={format} locale="pt">{item.created_at}</Moment></span>
                            <p>{item.phase_definition}</p>
                            <p>{item.notes}</p>
                        </li>
                    );
                });
            }
        }
    }

    const showAppName = ()=>{
        const appName = selectedTrack.meta_data.app_info.name;
        const orgName = selectedTrack.meta_data.app_info.organization;

        if( appName !== "" && orgName !== ""){
            return (appName === orgName)? appName : appName + " / " + orgName
        }else{
            if(appName !== ""){
                return appName;
            }

            if(orgName !== ""){
                return orgName;
            }

            return "";

        }
    }

    const showDueAt = ()=>{
        const due_at = selectedTrack.due_at;

        if(due_at !== "" && due_at !== null){
            return (
                <Moment format="DD MMMM, YYYY" locale="pt">{selectedTrack.due_at?selectedTrack.due_at:''}</Moment>
            )
        }

        return 'Não definido'
    }

    const processLink = ()=>{
        const url = selectedTrack.url;

        if(url !=='' && url !== null){
            return (
                <a class="readmore-link" href={url} target="_blank" rel="noopener noreferrer" title="Saiba mais">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-link`}>
                               Saiba Mais
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon icon={faLink} size="lg"/> 
                    </OverlayTrigger>
                    
                </a>
            )
        }

        return '';
    }

    const detailsTitle = ()=>{
        if(selectedTrack.process_definition){
            return (selectedTrack.process_definition.description)? selectedTrack.process_definition.description + " - " + selectedTrack.code : selectedTrack.code;
        }

        return selectedTrack.code        
    }

    useEffect(() => {
        if (props.selectedTrack != null) {
            setSelectedTrack(props.selectedTrack);
        }

    }, [props.selectedTrack]);

    useEffect(() => {
        if (selectedTrack.meta_data && selectedTrack.meta_data.execution_percent && selectedTrack.meta_data.phase_definition_execution) {
            setExecutionPercent(selectedTrack.meta_data.execution_percent);
            setPhaseDefinitionExecution(selectedTrack.meta_data.phase_definition_execution);
        }
    }, [selectedTrack]);

    return (
        <div className="component-trackdetail">
            <div className="row shop-tracking-status">
                <div className="col-sm-12 ">
                    <div className="trackdetail-header">
                        <h1 style={{display:'flex'}}>
                            {detailsTitle()} 
                            {processLink()}
                        </h1>                      
                        
                        <span style={{color:'#ccc'}}>{selectedTrack.description}</span>
                        <span style={{color:'#ccc', display:'flex'}}>
                            <FontAwesomeIcon icon={faHome} size="lg" style={{fontSize:'14px', marginTop:'5px', marginRight:'5px'}}/>
                            {showAppName()}
                        </span>
                    </div>
                    <div className="content2">
                        <div className="content2-header1">
                            <p>Criado no Dia : <span> <Moment format="DD-MM-YYYY">{selectedTrack.created_at}</Moment></span></p>
                        </div>
                        <div className="content2-header1">
                            <p>Estado : <span>{selectedTrack.status}</span></p>
                        </div>
                        <div className="content2-header1">
                            <p>Data esperado de Entrega : <span>{showDueAt()}</span></p>
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="well my-5 position-relative">
                        {
                            selectedTrack.process_definition && selectedTrack.process_definition.phase_definitions &&
                            (<ProgressBar className="order-status-timeline position-absolute" variant="warning" now={Number(executionPercent)} max={100} min={0} />)
                        }

                        <ul className="nav d-flex justify-content-between position-relative" style={{ zIndex: 12 }}>
                            {
                                selectedTrack.process_definition && selectedTrack.process_definition.phase_definitions &&
                                selectedTrack.process_definition.phase_definitions.map((item, index) => {
                                    return (
                                        <li
                                            className={(phaseDefinitionExecution[item.id] === 1 ? 'color-green ' : phaseDefinitionExecution[item.id] === 0 ? 'color-gray ' : 'color-red ') + "text-center nav-item"}
                                            key={index + 1200}
                                        >
                                            <OverlayTrigger
                                                key={index}
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-${index}`}>
                                                        {ICON_FEEDBACK.map(el => phaseDefinitionExecution[item.id] === el.status ? el.message : "")}
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="icon-border">
                                                    <FontAwesomeIcon icon={phaseDefinitionExecution[item.id] === 1 ? faCheck : phaseDefinitionExecution[item.id] === 0 ? faClock : faExclamation} size="lg" />
                                                </span>
                                            </OverlayTrigger>
                                            <br />
                                            <span>{item.name}</span>
                                        </li>
                                    );
                                })
                            }

                            {/* <li className={Number(executionPercent) === 100 ? 'color-green ' : 'color-gray ' + "text-center  nav-item"}>
                                <span className="icon-border">
                                    <FontAwesomeIcon icon={Number(executionPercent) === 100 ? faCheck : faClock} size="lg" />
                                </span>
                                <br />
                                <span>Completo</span>
                            </li> */}
                        </ul>
                    </div>
                    <h4 className="trackdetail-h4">Detalhes de Acompanhamento</h4>
                    <ul className="timeline">
                        {
                            trackPhasesDetails()
                        }
                    </ul>
                </div>
            </div>

            <div className="float-button--container">
                <span className="float-button" id="menu-share">
                    <FontAwesomeIcon icon={faEllipsisV} size="lg" />
                </span>
                <ul className="float-button--ul">
                    <li >
                        <span style={{ cursor: 'not-allowed', color: '#ccc' }}>
                            <FontAwesomeIcon icon={faClone} className="my-float" size="lg" />
                        </span>
                    </li>
                    <li >
                        <span style={{ cursor: 'not-allowed', color: '#ccc' }}>
                            <FontAwesomeIcon icon={faStar} className="my-float" size="lg" />
                        </span>
                    </li>
                    <li>
                        <span onClick={() => { props.reload() }}>
                            <FontAwesomeIcon icon={faSyncAlt} className="my-float" size="lg" />
                        </span>
                    </li>
                </ul>
            </div>

        </div>
    );
}

export default TrackDetailComponent;