import React from 'react';
import {Router, Route, Switch} from "react-router-dom";
import history from './helper/history';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/home/home';
import TrackDetail from './pages/track/trackDetail';
import Contact from './pages/contact/contact';

function App() {
  return (
    <div className="app">      
      <Router history={history}>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/t/:numbers" component={TrackDetail}/>
            <Route path="/t" component={Home}/>
          </Switch>
      </ Router>
    </div>
  );
}

export default App;
