import React, { useState, useEffect } from "react";
import './trackDetail.css';
import Footer from '../../components/footer/footer';
import NavbarComponent from "../../components/navbar/navbar";
import Track from "../../components/track/track";
import { Container, Row, Col, ListGroup, Media, ToggleButtonGroup, ToggleButton } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faQuestionCircle, faClock } from "@fortawesome/free-regular-svg-icons";
import { faFilter, faCheck, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import TrackDetailComponent from "../../components/track/trackDetail";

import useApiRequest from '../../useApiRequest/';
import { FETCHING, SUCCESS, ERROR } from '../../useApiRequest/actionTypes';
import { getToken } from '../../auth';
import { host, apiurl } from '../../helper';

const TrackDetail = (props) => {
    const [trackNumbers, setTrackNumbers] = useState([]);
    const [trackNumbersToBeRendered, setTrackNumbersToBeRendered] = useState([]);
    const [selectedNumber, setSelectedNumber] = useState('');
    const [token] = useState(getToken());
    const [endpoint, setEndpoint] = useState();
    const [filterValues, setFilterValues] = useState([]);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [filteredTrackNumbers, setFilteredTrackNumbers] = useState([])

    const [{ status, response }, makeRequest] = useApiRequest(
        `${host}${apiurl}/process/code/${endpoint}`,
        {
            token: `${token}`,
            options: {
                'method': 'GET'
            }
        }
    );

    const sideNumberListClick = (number) => {
        if (trackNumbers.find((item) => item === number)) {
            setSelectedNumber(number);
        }
    }

    const addNumber = (number) => {
        if (!trackNumbers.find((item) => item === number) && number !== '' && typeof number !== 'undefined') {
            setTrackNumbers([
                ...trackNumbers,
                number
            ]);

            setSelectedNumber(number);
        }
    }

    const removeNumber = (number) => {
        let trackNum = trackNumbers;
        let newArr = trackNum.filter((value) => { return value !== number });

        if (newArr.length > 0) {
            if (selectedNumber === number) {
                setSelectedNumber(newArr[0])
            }
        } else {
            setSelectedNumber('');
        }

        setTrackNumbers(newArr);

        return false;
    }

    const getProcessByCode = (arr, code) => {
        return arr.find((item) => item.code === code);
    }

    const getProcessCodeStatus = (arr, code) => {
        let process = getProcessByCode(arr, code);
        if (process !== null && typeof process !== 'undefined') {
            switch (process.status.toLowerCase()) {
                case 'in progress':
                case 'em progresso':
                    return (
                        <React.Fragment>
                            <FontAwesomeIcon className="listgroup-item--media item-span--active" icon={faClock} />
                            <Media.Body>
                                <h5 className="listgroup-item--p">{code}</h5>
                                <span className="listgroup-item--span item-span--active">
                                    Activo
                                </span>
                            </Media.Body>
                        </React.Fragment>
                    );

                case 'finish':
                case 'concluído':
                    return (
                        <React.Fragment>
                            <FontAwesomeIcon className="listgroup-item--media item-span--endsuccessfully" icon={faCheck} />
                            <Media.Body>
                                <h5 className="listgroup-item--p">{code}</h5>
                                <span className="listgroup-item--span item-span--endsuccessfully">
                                    Concluído
                                </span>
                            </Media.Body>
                        </React.Fragment>
                    );

                case 'canceled':
                case 'cancelado':
                    return (
                        <React.Fragment>
                            <FontAwesomeIcon className="listgroup-item--media item-span--canceled" icon={faExclamationCircle} />
                            <Media.Body>
                                <h5 className="listgroup-item--p">{code}</h5>
                                <span className="listgroup-item--span item-span--canceled">
                                    Cancelado
                                </span>
                            </Media.Body>
                        </React.Fragment>
                    );

                default:
                    return (
                        <React.Fragment>
                            <FontAwesomeIcon className="listgroup-item--media item-span--unknown" icon={faQuestionCircle} />
                            <Media.Body>
                                <h5 className="listgroup-item--p">{code}</h5>
                                <span className="listgroup-item--span item-span--unknown">Desconhecido</span>
                            </Media.Body>
                        </React.Fragment>
                    );
            }
        }

        return (
            <React.Fragment>
                <FontAwesomeIcon className="listgroup-item--media item-span--unknown" icon={faQuestionCircle} />
                <Media.Body>
                    <h5 className="listgroup-item--p">{code}</h5>
                    <span className="listgroup-item--span item-span--unknown">Desconhecido</span>
                </Media.Body>
            </React.Fragment>
        )
    }

    useEffect(() => {
        let arrNumbers = props.match.params.numbers.split(',');

        if (arrNumbers.length > 0) {
            setSelectedNumber(arrNumbers[0]);
            setTrackNumbers(arrNumbers);
        }

    }, [props.match.params.numbers]);

    useEffect(() => {
        if (trackNumbers.length > 0) {
            setEndpoint(trackNumbers);
        }
    }, [trackNumbers]);

    useEffect(() => {
        if (endpoint !== '' && typeof endpoint !== 'undefined') {
            loaddata();
        }
    }, [endpoint]); // eslint-disable-line

    useEffect(() => {
        if (response && response.result) {

            let filteredTrackNumbers = []
            const responseData = response.result

            trackNumbers.forEach(number => {
                responseData.forEach(objct => {
                    if (number === objct.code) {
                        filteredTrackNumbers.push({
                            code: objct.code,
                            status: objct.status
                        })
                    } else {
                        filteredTrackNumbers.push({
                            code: number,
                            status: "Desconhecido"
                        })
                    }
                })
            })

            setFilteredTrackNumbers(filteredTrackNumbers);
        }
    }, [response, trackNumbers])

    useEffect(() => {
        if (filterValues.length) {

            let filteredNumbers = [];
            filterValues.forEach(item => {
                filteredTrackNumbers.forEach(objct => {
                    if (objct.status === item)
                        filteredNumbers.push(objct.code);
                })
            })
            setTrackNumbersToBeRendered(filteredNumbers)
        } else {
            setTrackNumbersToBeRendered([])
        }

    }, [filterValues])

    useEffect(() => {

        trackNumbersRender();

    }, [trackNumbers, trackNumbersToBeRendered])

    const loaddata = () => {
        makeRequest()
    }
    const filterNumberRender = () => {
        switch (status) {
            case SUCCESS:
                return (
                    <React.Fragment>
                        {response.result.length} de {trackNumbers.length} Códigos Encontrado
                    </React.Fragment>
                );

            case FETCHING:
                return (
                    <React.Fragment>Buscando... Códigos</React.Fragment>
                );

            case ERROR:
                return (
                    <React.Fragment>
                        Nenhum código de acompanhamento foi encontrado
                    </React.Fragment>
                );

            default:
                return (
                    <React.Fragment>
                        Nenhum código de acompanhamento foi encontrado
                    </React.Fragment>
                );
        }
    }

    const trackNumbersRender = () => {
        const trackNmbr = trackNumbersToBeRendered.length ? trackNumbersToBeRendered : trackNumbers
        return trackNmbr.map((item, index) => {
            let content = '';
            switch (status) {
                case SUCCESS:
                    content = getProcessCodeStatus(response.result, item);
                    break;
                case FETCHING:
                    content = (
                        <React.Fragment>
                            <FontAwesomeIcon className="listgroup-item--media item-span--unknown" icon={faQuestionCircle} />
                            <Media.Body>
                                <h5 className="listgroup-item--p">{item}</h5>
                                <span className="listgroup-item--span item-span--unknown">Buscando...</span>
                            </Media.Body>
                        </React.Fragment>
                    );
                    break;
                case ERROR:
                    content = (
                        <React.Fragment>
                            <FontAwesomeIcon className="listgroup-item--media item-span--unknown" icon={faQuestionCircle} />
                            <Media.Body>
                                <h5 className="listgroup-item--p">{item}</h5>
                                <span className="listgroup-item--span item-span--unknown">Desconhecido</span>
                            </Media.Body>

                        </React.Fragment>
                    );
                    break;

                default:
                    content = (
                        <React.Fragment>
                            <FontAwesomeIcon className="listgroup-item--media item-span--unknown" icon={faQuestionCircle} />
                            <Media.Body>
                                <h5 className="listgroup-item--p">{item}</h5>
                                <span className="listgroup-item--span item-span--unknown">Desconhecido</span>
                            </Media.Body>
                        </React.Fragment>
                    );
                    break
            }


            return (
                <ListGroup.Item className={'list-group--pointer ' + ((selectedNumber === item) ? 'listgroup-item--active' : '')} key={index + 100}>
                    <Media onClick={() => sideNumberListClick(item)}>
                        {content}
                    </Media>
                    <FontAwesomeIcon icon={faTimesCircle} onClick={() => removeNumber(item)} />
                </ListGroup.Item>
            )
        })
    }

    const trackDetailsRender = () => {
        switch (status) {
            case SUCCESS:
                if (typeof response !== "undefined" && response !== null) {

                    if (typeof response.result !== 'undefined' && response.result !== null) {

                        if (response.result.length > 0) {

                            let process = getProcessByCode(response.result, selectedNumber);

                            if (process !== '' && typeof process !== 'undefined') {
                                return (
                                    <React.Fragment>
                                        <TrackDetailComponent selectedTrack={process} reload={loaddata} />
                                    </React.Fragment>
                                );
                            }

                        }

                    }

                }

                return (
                    <React.Fragment>
                        Código de Acompanhamento não encontrado
                    </React.Fragment>
                );

            case FETCHING:
                return (
                    <div className="api-request__fetching">Buscando...</div>
                );

            case ERROR:
                return (
                    <React.Fragment>
                        Código de Acompanhamento não encontrado
                    </React.Fragment>
                );

            default:
                return (
                    <React.Fragment>
                        Código de Acompanhamento não encontrado
                    </React.Fragment>
                );
        }
    }

    const handleFilterChange = (arrayOfValue) => {
        setFilterValues(arrayOfValue);
    }

    return (
        <div className="app-pages--trackdetail">
            <NavbarComponent />
            <section className="section-trackdetail">
                <Container fluid={true}>
                    <Row>
                        <Col md={3} >
                            <ListGroup variant="flush">
                                <ListGroup.Item className="listgroup-item--header">
                                    <div className="float-left">
                                        <p className="listgroup-item--small">
                                            {
                                                filterNumberRender()
                                            }
                                        </p>
                                    </div>
                                    <div onClick={() => setShowFilterOptions(!showFilterOptions)} className="float-right" style={{ /*cursor: 'not-allowed',*/ color: '#ccc' }} >
                                        <FontAwesomeIcon icon={faFilter} /> Filtro
                                    </div>
                                </ListGroup.Item>
                                {showFilterOptions ? (
                                    <ListGroup.Item>
                                        <div>
                                            <ToggleButtonGroup type="checkbox" value={filterValues} onChange={handleFilterChange}>
                                                <ToggleButton size="sm" variant={filterValues.includes("IN PROGRESS") ? 'primary' : "outline-primary"} value="IN PROGRESS">Ativo</ToggleButton>
                                                <ToggleButton size="sm" variant={filterValues.includes("FINISH") ? 'primary' : "outline-primary"} value="FINISH">Terminado</ToggleButton>
                                                <ToggleButton size="sm" variant={filterValues.includes("CANCELED") ? 'primary' : "outline-primary"} value="CANCELED">Cancelado</ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                    </ListGroup.Item>
                                ) : null}
                                <ListGroup.Item>
                                    <Track isInput="true" addNumber={(number) => addNumber(number)} textareaPlaceholder="Introduza o código de Acompanhamento!" />
                                </ListGroup.Item>

                                {
                                    trackNumbersRender()
                                }

                            </ListGroup>
                        </Col>

                        <Col md={9} className="component-margin--25 mt-3 min-height--70">
                            {
                                trackDetailsRender()
                            }
                        </Col>

                    </Row>
                    <Footer />
                </Container>
            </section>
        </div>
    );
};

export default TrackDetail;